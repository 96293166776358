let base_path = '/api/';

let host = process.env.NODE_ENV === 'production' ? 'https://report.online-service.su' : 'http://localhost:8080';
host = 'https://merch.mifors.com'

let authHost = 'https://merch.mifors.com';
// let authHost = host;

export const config = {
  host: host,
  location: 'merch',
  apiUrl: host + base_path,
  // apiUrl: 'http://localhost:8080' + base_path,
  authUrl: authHost + '/merchauthserver/oauth/token',
  registerUrl: authHost + base_path + 'cabinet/register',
  confirmUrl: authHost + base_path + 'cabinet/confirm',
  passRestore: authHost + base_path + 'cabinet/restore',
  client: {
    id: 'fooClientIdPassword',
    secret: 'secret'
  },
  image: (uuid) => `${config.apiUrl}sp/photo/${uuid}`,
  temp: (uuid, ext) => `${config.apiUrl}tempfiles/${uuid}.${ext}`
}
